@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap);
html,
body,
#root {
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
}

a, a:hover, a:active{
  color: black;
}

.thumbnail {
  animation: fadeInAnimation ease 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: #e4e6e8;
}

.pricetag {
  transform: translate(-10px, 0px);
  width: 100%;
  height: 0;
  display: inline;
  float: right;
  text-align: right;
  opacity: 0.5;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#root {
  background-color: #dcdcdc;
}


.captcha_container{
  transform: translate(-22px, 0) scale(0.85) !important;
}

#window_field {
  position: absolute;
  max-width: 1280 px;
  max-height: 720 px;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 90%;
  overflow: hidden;
  border-radius: 16px;
}

#main_preview {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 310px);
  background-color: #f4f2f2;
}
#main_form {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 310px;
  background-color: #ffffff;
  padding: 10px;
}
#main_form_upper {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100% - 80px);
  width: 100%;
}

#main_form_under {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80px !important;
  width: calc(100% - 64px);
  margin-left: 32px;
  margin-right: 32px;
  padding-top: 15px;
}

#main_modellselection{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #F4F2F2;
  padding: 10px;
}

#main_modellselection_container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 820px;
  height: 400px;
  padding: 50px;
  text-align: center;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

.modellfamily_item{
  position: relative;
  float: left;
  margin: 15px;
  width: 210px;
  height: 300px;
  overflow: hidden;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
  transition: all .15s ease-in-out;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  animation: fadeInAnimation ease 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.modellfamily_description{
  font-size: 14px;
}

.modellfamily_item:hover {
  transform: scale(1.05);
 
}
.modellfamily_item:active {
  transform: scale(1.1);
  transition: all .05s ease-in-out;

}
.modellfamily_image{
  position: absolute;
  width: 100%;
  height: 60%;
}
.modellfamily_text{
  padding: 20px 20px;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 40%;
  background-color: white;
  text-align: left;
}

.modellfamily_image img{
  top: 0;
  width: auto;
  height: 100%;
}








.navigation_arrow {
  position: relative;
  display: inline-block;
  height: 100px;
  width: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

input.error {
  border-color: red;
}
.errormessage {
  color: red;
  margin-left: 6px;
  margin-top: -10px;
  line-height: 1.2rem;
  margin-bottom: 10px;
}

body {
  background: white;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  justify-content: center;
}

h1 {
  position: absolute;
  top: 43px;
  left: 140px;
  padding: 0;
  margin: 40px;
  font-size: 5em;
  font-weight: 600;
  line-height: 0.7em;
  letter-spacing: -6px;
  color: #272730;
}

b {
  color: #4b4848;
}

.modal-title {
  font-weight: 600;
  color: #272730;
}

.form_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 70px;
}

.form_width {
  width: 256px;
  height: auto;
  margin-left: 27px;
  margin-right: 27px;
}

.picker {
  position: absolute !important;
  top: 74px;
  left: 70px;
  width: 90px !important;
  height: 90px !important;
}

.thumbnail {
  float: left;
  width: 38px;
  height: 38px;
  margin: 5px;

  border-radius: 50%;
}

.description_model {
  margin-left: 15px;
  margin-bottom: 10px;
}

.react-select__control {
  width: 100% !important;
}

.select-wrapper {
  margin-left: -2px;
  margin-right: -2px;
}

.thumbnail:hover,
.activethumbnail,
.is_selected {
  box-shadow: 0px 0px 0px 2px #bcbcbc;
}
.thumbnail:active {
  box-shadow: 0px 0px 0px 2px #727272;
}

.form_title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
  color: #666666;
}
.marginleft5px {
  margin-left: 5px;
}

.form_segment {
  margin-top: 25px;
  margin-bottom: 25px;
}
.form_segment:after {
  clear: both;
  height: 0;
  width: 100%;
  content: "";
  display: block;
}
.separater {
  border: 1px solid #d7d4d4;
}

#rotate3d {
  transition-duration: 0.7s;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -5px;
  margin-left: -25px;
  width: 50px;
  height: auto;
  z-index: 100;
  pointer-events: none;
  animation: left_right_move 3s 1s infinite alternate ease-in-out;
}

@keyframes left_right_move {
  from {
    transform: translate(calc(+40px)) rotate(5deg);
  }
  to {
    transform: translate(calc(-40px)) rotate(-5deg);
  }
}

#logo {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 140px;
  height: auto;
  z-index: 100;
  opacity: 0.7;

  image-rendering: -moz-crisp-edges; /* Firefox        */
  image-rendering: -o-crisp-edges; /* Opera          */
  image-rendering: -webkit-optimize-contrast; /* Safari         */
  image-rendering: optimize-contrast; /* CSS3 Proposed  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+           */
}

.arrow_left,
.arrow_right {
  position: absolute;
  padding: 10px;
  width: 58px;
  opacity: 0.7;
  cursor: pointer;
}

.arrow_left {
  left: 0;
}
.arrow_right {
  right: 0;
}

.arrow_left:hover,
.arrow_right:hover {
  opacity: 1;
}

.reacttooltip {
  position: absolute;
  overflow: visible;
  z-index: 10000;
}

.react-select__control {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  width: 200px;

  padding: 3px;
  float: auto;

  border-radius: 8px !important;
  border-style: none;
  border: 1px solid #e4e6e8 !important;
  transition: 0.1s ease;
}

.MuiFormControl-root {
  margin-top: -3px;
}

input,
textarea,
select {
  font-family: "Arial", sans-serif;
  font-weight: normal !important;
  position: relative;
  display: block;
  width: 100%;
  margin: 13px 0;
  padding: 1em;

  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
}

.input-group {
  margin: 13px 0;
}
.input-group input {
  margin: 0 0;
}

.input-group .input-group-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.input-group .input-group-text:hover {
  background-color: #d1d1d1;
}

.input-group .input-group-text:active {
  background-color: #a8a8a8;
}
.btn {
  box-shadow: none !important;
  border-radius: 4px;
}
.btn-group {
  width: 100%;
  margin: 13px 0;
}

.btn-check {
  cursor: default !important;
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #444343;
  border-color: #444343;
}

.btn-outline-dark:hover {
  color: black;
  background-color: #e6e6e6;
  border-color: #444343;
}

.MuiInputBase-input,
.MuiInput-input {
  font-weight: 600;
  font-family: "Arial", sans-serif;
}

textarea {
  resize: vertical;
  max-height: 5000px;
  min-height: 120px;
}

input:hover {
  border-color: #e4e6e8;
}
input:focus,
input:focus-visible,
input[type="text"]:focus {
  outline-color: #555;
  border-color: #555;
  background-color: white;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: normal;
}

.clear_both {
  height: auto;
  position: relative;
  display: block;
  margin-top: 13px;
  margin-bottom: 13px;
}

.clear_both input {
  margin-top: 0 !important;
  margin-bottom: 13px !important;
}

.adress {
  float: left;
  width: calc(70% - 10px);
  margin-right: 10px;
}

.adress_nr {
  float: left;
  width: 30%;
}

.place {
  float: right;
  width: calc(70% - 10px);
}

.plz {
  float: left;
  width: 30%;
  margin-right: 10px;
}

button,
.button {
  width: 100%;
  margin: 10px 0;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.05s ease-in;
  text-align: center;
  text-decoration: none;
  color: #4a4a4a;
  background-color: white;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 15px;
}

.auto_button_width {
  width: auto !important;
}

button:hover,
.button:hover {
  background-color: #e7e7e7;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.info_thumbnail {
  width: 20px;
  margin-left: 5px;
  margin-top: -4px;
  opacity: 0.5;
}
.info_thumbnail:hover {
  opacity: 0.8;
}
.info_thumbnail_containter {
  display: inline-block;
}

.react-select__option--is-selected {
  background-color: #e4e4e4;
  color: black;
}
.react-select__option--is-focused {
  background-color: #c0c0c0;
  color: black;
}

#viewport_image {
  position: static;
  left: -1000px;
  top: -1000px;
  width: 800px !important;
  height: 400px !important;
}
#viewport_image canvas {
  width: 800px !important;
  height: 400px !important;
}

.loading_spinner {
  width: 4vmax;
  height: 4vmax;
  border-right: 4px solid #272730;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
}

.loading_spinner:before,
.loading_spinner:after {
  content: "";
  width: 3vmax;
  height: 3vmax;
  display: block;
  position: absolute;
  top: calc(50% - 1.5vmax);
  left: calc(50% - 1.5vmax);
  border-left: 3px solid #272730;
  border-radius: 100%;
  animation: spinLeft 800ms linear infinite;
}

.loading_spinner:after {
  width: 2vmax;
  height: 2vmax;
  top: calc(50% - 1vmax);
  left: calc(50% - 1vmax);
  border: 0;
  border-right: 2px solid #272730;
  animation: none;
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.MuiFormControl-root {
  margin-top: 0px !important;
  width: 100%;
  padding-left: 13px;
  padding-right: 20px;
}
label {
  padding-left: 13px;
}

.modal-content {
  border-radius: 12px;
  border: none;
}

table {
  table-layout: fixed;
  width: 100%;
}
td,
th {
  padding: 0 5px;
  vertical-align: top;
  text-align: left;
}

@media (max-aspect-ratio: 5/5) and (max-width: 600px) {
  #main_form {
    width: 100%;
    height: 65%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
  }
  #main_preview {
    width: 100%;
    height: 35%;
    bottom: auto;
    left: 0;
    right: 0;
    top: 0;
  }
  .form_width {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  #main_form_upper {
    height: calc(100% - 60px);
    
  }
  #main_form_under {
    height: 60px;
    padding-top: 25px;
    width: 266px;
    left: calc(50% - 133px);
    margin: 0;
  }
}

@keyframes enter {
  0% {
    opacity: 0;
    top: -10px;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: 10px;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 55px;
  height: 55px;
  margin-left: -27.5px;
  margin-top: -27.5px;
}

.square {
  background: #d7d4d4;
  width: 15px;
  height: 15px;
  float: left;
  top: -10px;
  margin-right: 5px;
  margin-top: 5px;
  position: relative;
  opacity: 0;
  animation: enter 6s infinite;
}

.enter {
  top: 0px;
  opacity: 1;
}

.square:nth-child(1) {
  animation-delay: 1.8s;
}

.square:nth-child(2) {
  animation-delay: 2.1s;
}

.square:nth-child(3) {
  animation-delay: 2.4s;
  background: #fdc96f;
}

.square:nth-child(4) {
  animation-delay: 0.9s;
}

.square:nth-child(5) {
  animation-delay: 1.2s;
}

.square:nth-child(6) {
  animation-delay: 1.5s;
}

.square:nth-child(8) {
  animation-delay: 0.3s;
}

.square:nth-child(9) {
  animation-delay: 0.6s;
}

.clear {
  clear: both;
}

.last {
  margin-right: 0;
}

